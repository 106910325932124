<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <router-link to="/reports/custom" class="btn btn-w-shadow btn-sm btn-back" style="margin-right: 20px;">
						<inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)"/>
					</router-link>
                    <h1 class="heading-page-h1">{{ edit ? $translate('edit') : $translate('create')}} {{ $translate('custom-reports') }}</h1>
                </div>
                <div class="content__header-right">
                    <WhitePlusButton @click="addColumn"> {{ $translate('add-column') }} </WhitePlusButton>
                    <WhitePlusButton @click="addRow"> {{ $translate('add-entry') }} </WhitePlusButton>
                </div>
            </div>
            <div class="table-wrapper">
                <table class="table editable_table" :key="tableKey">
                    <thead>
                        <tr>
                            <th v-for="(columnName, index) in tableColumns" :key="index" contenteditable="true" @blur="setColumnName($event, index)">
                                <div class="table-filter">
                                    <span>{{ columnName }}</span>
                                    <span @click="deleteColumn(index)">
                                        <inline-svg class="table-filter__delete" :src="require(`@/assets/img/trash-delete.svg`)" />
                                    </span>
                                </div>
                            </th>
                            <th class="text-end">
                                <div class="table-filter">
                                    <span>{{ $translate('actions') }}</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" v-for="(row, rowIndex) in tableData" :key="rowIndex">
                            <td v-for="(column, columnIndex) in tableData[rowIndex]" :key="columnIndex" contenteditable="true" @blur="setCellContent($event, rowIndex, columnIndex)">
                                {{ column }}
                            </td>
                            <td class="text-end">
                                <div class="table-actions flex items-center justify-end">
                                    <button type="button" class="btn btn-icon red-color" @click="deleteRow(rowIndex)">
                                        <inline-svg :src="require(`@/assets/img/trash-delete.svg`)" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="items-group cards border-top">
                <div class="cards__col-6">
                    <div class="el-form mt-15 flex-auto">
                        <label class="el-form__title">{{ $translate('report-name') }}</label> 
                        <input type="text" class="input" v-model="config.name"/>
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15 flex-auto">
                        <label class="el-form__title">{{ $translate('product') }}</label> 
                        <DropdownAdvanced 
                            :options="products"
                            v-model="config.product"
                        ></DropdownAdvanced>
                    </div>
                </div>
            </div>
            <div class="items-group cards" style="padding-bottom: 68px;margin-top:25px">
                <div class="cards__col-6">
                    <div class="section-header" style="height:38px">
                        <h1>{{ $translate('image') }}</h1>
                        <!-- <WhitePlusButton @click="addImages">Add Images</WhitePlusButton> -->
                    </div>

                    <div class="item-body">
                        <div class="gallery">
                            <div class="gallery__item" v-for="image in images" :key="image.url">
                                <img :src="image.url" alt="" />
                                <button class="delete-gallery__item btn" data-target="delete-media" @click.prevent="deleteImage(image.url)">
                                    <inline-svg :src="require(`@/assets/img/x.svg`)" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="fake-dropzone" :class="{ 'drop-ready': dragState }" @drop.prevent.stop="dropImage" @dragenter.prevent @dragover.prevent style="margin-top: 58px">
                        <label>
                            <img src="@/assets/img/upload-image-icon.svg" />
                            <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
                            <input type="file" @change="addImage" multiple />
                        </label>
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="section-header">
                        <h1>{{ $translate('comments') }}</h1>
                        <WhitePlusButton @click="addComment">{{ $translate('add-comment') }}</WhitePlusButton>
                    </div>
                    <div class="el-form mt-15" v-for="id in Object.keys(comments)" :key="id">
                        <div class="el-form__title flex flex-end" style="text-align: right">
                            <button type="button" class="btn btn-icon red-color" @click="deleteComment(id)">
                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                            </button>
                        </div>
                        <textarea :placeholder="$translate('comment-decsription')" class="textarea" v-model="comments[id]"></textarea>
                    </div>
                </div>
            </div>
        </main>
        <div class="pagination wizard-actions fixed">
            <div class="pagination__body">
                <router-link to="/reports/custom" type="button" class="btn btn-w-shadow btn-standart-2"> {{ $translate('cancel') }} </router-link>

                <button type="button" class="btn btn-primary btn-standart icon-rigth" @click="createCustomReport" :class="{ processing }">
                    {{ edit ? $translate('save') : $translate('create') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { listCategories } from "@/graphql/queries";
import { API, Storage } from "aws-amplify";
import { createCustomReport, updateCustomReport } from "@/graphql/mutations";
import { compareData } from "@/helpers";

import Popups from "@/components/popups";
import Base from "@/components/base";
import Buttons from "@/components/buttons";
import SimplePagination from "./SimplePagination";

export default {
    components: {
        ...Popups,
        ...Base,
        ...Buttons,
        SimplePagination,
    },
    data() {
        return {
            // tableData: [{}],
            config:{
                name:null,
                product:null,
            },
            comments: {},
            images: [],
            dragState: false,
            tableColumns: [],
            tableData: [],
            tableKey: 0,
            processing: false,
            edit: false,
            backup: null,
        };
    },
    computed: {
        products(){
            let products = this.$store.state.products || []
            return products.map(p=>({id:p.id,name:p.title}))
        },
        customReports(){
            return this.$store.state.customReports;
        }
    },
    methods: {
        async createCustomReport() {
            this.processing = true;
            let images = [];

            let imagesRequests = this.images.map(async (img) => {
                let name = null;
                if(this.edit){
                    name = `/images/${img.url.split("/")[img.url.split("/").length - 1].split('?')[0]}`;
                    images.push(name);
                    if(!this.backup.images.includes(name)){
                        const s3File = await Storage.put(name, img.file);
                        return s3File.key;
                    }
                } else {
                    name = `/images/${img.url.split("/")[img.url.split("/").length - 1]}`;
                    const s3File = await Storage.put(name, img.file);
                    return s3File.key;
                }
            });

            if(this.edit) {
                await Promise.all(imagesRequests);
            } else {
                await Promise.all(imagesRequests).then((res) => images = res);
            }

            const input = {
                ...this.config,
                tableColumns: this.tableColumns,
                tableData: this.tableData,
                images: images,
                comments: Object.values(this.comments),
                type:"custom"
            };

            let report = null;

            if(this.edit){
                // const update = compareData(input, this.backup, ["comments", "images", "tableColumns", "tableData"]);
                report = await API.graphql({
                    query: updateCustomReport,
                    variables: {
                        input: {   
                            id: this.backup.id,
                            ...input,
                        }
                    },
                }).then((res) => res.data.updateCustomReport);
            } else {
                report = await API.graphql({
                    query: createCustomReport,
                    variables: { input },
                }).then((res) => res.data.createCustomReport);
            }

            console.log("report", report);
            this.tableKey++
            // this.$store.commit('ADD_ITEM', { field: 'products', item: product })
            this.processing = false;

            this.$router.push({ path: '/reports/custom'})
        },
        setCellContent(event, rowIndex, columnIndex) {
            // this.$set(this.tableData[rowIndex],colName,event.target.innerText)
            this.tableData[rowIndex][columnIndex] = event.target.innerText;
        },
        deleteRow(rowIndex) {
            // console.log("delete",rowIndex,this.tableData);
            // let tableDataCopy=[...this.tableData]
            // tableDataCopy.splice(rowIndex,1)
            // this.tableData=tableDataCopy
            // console.log("delete",rowIndex,this.tableData);
            this.tableData.splice(rowIndex, 1);
            this.tableKey = Date.now();
        },
        deleteColumn(columnIndex) {
            this.tableColumns.splice(columnIndex, 1);
            this.tableData.forEach((row, rowIndex) => {
                row.splice(columnIndex, 1);
            });
            this.tableKey = Date.now();
        },
        addRow() {
            if (this.tableColumns.length == 0) {
                return;
            }
            let newRow = [];
            for (let i = 0; i < this.tableColumns.length; i++) {
                newRow.push("");
            }
            this.tableData.push(newRow);
            this.tableKey = Date.now();
        },
        addColumn() {
            this.tableColumns.push("column");
            for (let rowIndex = 0; rowIndex < this.tableData.length; rowIndex++) {
                // console.log("s",row);
                this.tableData[rowIndex].push("");
            }
        },
        setColumnName(event, index) {
            this.tableColumns[index] = event.target.innerText;
        },

        created() {
            this.addRow();
        },
        addComment() {
            // this.comments[Date.now()]=""
            this.$set(this.comments, Date.now() + " ", "");
        },
        deleteComment(id) {
            this.$delete(this.comments, id);
        },
        dropImage(ev) {
            const event = { target: ev.dataTransfer };
            this.addImage(event);
            this.dragState = false;
        },

        dragOver(ev) {
            this.dragState = true;
        },
        addImage(ev) {
            [...ev.target.files].forEach((file) => {
                this.images.push({
                    url: URL.createObjectURL(file),
                    file: file,
                });
            });
        },
        async deleteImage(url) {
            const options = { title: `Delete media?`, size: "sm", okLable: "Confirm", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    let index = this.images.findIndex((i) => i.url == url);
                    if (index !== -1) {
                        this.images.splice(index, 1);
                    }
                }
            });
        },
        genKey(index) {
            return Date.now() + "-" + index;
        },
        // createCustomReport() {
        //     console.log("create");
        // },
    },
    async created(){
        if(this.$route.name === 'EditCustom'){
            await this.$store.dispatch('GET_CUSTOMREPORTS');
            let findedReport = this.customReports.find(item => item.id === this.$route.params.id);
            this.backup = { ...findedReport };
            console.log('findedReport:', findedReport);
            this.tableColumns = findedReport.tableColumns;
            this.tableData = findedReport.tableData;
            this.comments = findedReport.comments;
            this.config = {
                name: findedReport.name,
                product: findedReport.product,
            }

            let imagesRequests=findedReport.images.map(async key=>{
                return {url:await Storage.get(key)}
            })
            await Promise.all(imagesRequests).then(res=> this.images=res);

            this.edit = true;
        }
    }
};
</script>

<style scoped>
.cards__col-6 {
    padding-left: 25px;
}
.comment {
    padding-top: 25px;
}
.flex-end {
    justify-content: end;
}
.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table-filter {
    position: relative;
}
.table-filter__delete {
    margin-left: 5px;
    width: 14px;
    height: 14px;
    transform: translateY(2px);
    color: var(--graph-red);
    cursor: pointer;
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px; */
}
/* .max-witdth{

} */
</style>
